<template>
	<el-dialog
		title="添加人员"
		:visible.sync="dialogVisible"
		:before-close="dialogBeforeClose">
		<el-form label-width="5.20833vw">
			<el-form-item label="角色名称：">
				<el-input disabled></el-input>
			</el-form-item>
			<el-form-item label="选择人员：">
				<el-select placeholder="选择人员" value="" class="input-select">
					<el-option label="1" value="选项1"></el-option>
					<el-option label="2" value="选项2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<p>如果该员工已被分配其它职务，添加后员工将被修改职务</p>
			</el-form-item>
		</el-form>
		<div slot="footer">
			<el-button @click="dialogVisible = false">取消</el-button>
			<el-button type="primary" @click="dialogVisible = false">保存</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	props: {
		staffHandleData: Object
	},
	data() {
		return {
			dialogVisible: false
		}
	},
	methods: {
		dialogBeforeClose() {
			this.dialogVisible = false;
		}
	},
	watch: {
		staffHandleData(data) {
			this.dialogVisible = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.el-form {
	.el-select {
		width: 100%;
	}
}
</style>
