<template>
	<div>
		<el-dialog
			:title="dialogTitle"
			:visible.sync="dialogVisible"
			:before-close="dialogBeforeClose"
			class="role-dialog">

			<el-form class="" label-width="5.20833vw">
				<el-form-item label="所属组织：">
					<el-select placeholder="选择组织" value="" class="input-select">
						<el-option label="1" value="选项1"></el-option>
						<el-option label="2" value="选项2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="角色名称：">
					<el-input type="text" placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="角色标识：">
					<el-input placeholder="输入内容"></el-input>
				</el-form-item>

				<el-form-item label="标签描述：">
					<el-input type="textarea" placeholder="输入内容" class="textarea-inputted" maxlength="100" show-word-limit></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer">
				<el-button @click="dialogBeforeClose">取消</el-button>
				<el-button type="primary" @click="dialogVisible = false">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		roleHandleData: Object,
	},
	data() {
		return {
			dialogTitle: '新建角色',
			dialogVisible: false,
		}
	},
	methods: {
		dialogBeforeClose() {
			this.dialogVisible = false;
		}
	},
	watch: {
		roleHandleData() {
			this.dialogVisible = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.role-dialog {
	.el-form {
		.el-select {
			width: 100%;
		}
		.textarea-inputted {
			/deep/.el-textarea__inner {
				height: 120px;
			}

		}
	}
}
</style>
