<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>角色管理</span>
		</div>

		<div class="course-wrapper-body">
			<div class="course-wrapper-function">
				<div class="function-left">
					<el-input placeholder="角色姓名" class="input-search"></el-input>
					<el-button type="primary" icon="el-icon-search">查询</el-button>
					<el-button icon="el-icon-refresh-left">重置</el-button>
				</div>
				<div class="function-right">
					<el-button @click="handleButtonRole" type="primary" icon="el-icon-plus">新建角色</el-button>
				</div>
			</div>

			<el-table :data="[{}]" class="feedback-table" border>
				<el-table-column prop="" label="ID"></el-table-column>
				<el-table-column prop="" label="所属组织"></el-table-column>
				<el-table-column prop="" label="角色名称"></el-table-column>
				<el-table-column prop="" label="角色标识"></el-table-column>
				<el-table-column prop="" label="成员数量" align="center"></el-table-column>
				<el-table-column prop="" label="角色描述"></el-table-column>
				<el-table-column prop="" label="状态" align="center" width="100">
					<el-switch active-color="#13ce66" inactive-color="#ff4949"></el-switch>
				</el-table-column>
				<el-table-column prop="" label="操作" class-name="table-handle" align="center" width="250">
					<el-button @click="handleButtonPermission" type="text">设置权限</el-button>
					<span>|</span>
					<el-button @click="handleButtonRole" type="text">编辑角色</el-button>
					<span>|</span>
					<el-button @click="handleButtonStaff" type="text">添加人员</el-button>
					<span>|</span>
					<el-button type="text" class="el-link--danger">删除</el-button>
				</el-table-column>
			</el-table>
		</div>

		<div class="course-wrapper-footer">
			<div class="footer-right">
				<el-pagination layout="prev, pager, next, sizes"></el-pagination>
			</div>
		</div>

		<role-dialog :roleHandleData="roleHandleData"></role-dialog>
		<staff-dialog :staffHandleData="staffHandleData"></staff-dialog>
	</div>
</template>

<script>
import roleDialog from '@/views/system/roleManage/roleDialog';
import staffDialog from '@/views/system/roleManage/staffDialog';
export default {
	name: 'roleManage',
	components: { roleDialog, staffDialog },
	data() {
		return {
			roleHandleData: {},
			staffHandleData: {},
		}
	},
	methods: {
		handleButtonPermission() {
			this.$router.push({
				name: 'rolePermission',
				params: {
					id: 1,
				}
			});
		},
		handleButtonRole() {
			this.roleHandleData = {};
		},
		handleButtonStaff() {
			this.staffHandleData = {};
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/css/common_c.scss';

</style>
